body {
  margin: 0;
  overflow: hidden;
  background: black;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

#root {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.App {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.ui-overlay {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;

  .buttons {
    position: absolute;
    right: 0;
    top: 0;
    padding: 16px;
    pointer-events: all;
  }

  .info-card {
    position: absolute;
    right: 0;
    width: 40%;
    padding: 32px;
    box-sizing: border-box;
    pointer-events: all;

    .description {
      overflow: auto;
      max-height: 50vh;
    }

    .dependencies,
    .sources {
      overflow: auto;
      max-height: 25vh;
    }
  }

  .license-backdrop {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    height: 100%;
    padding: 32px;
    box-sizing: border-box;
    pointer-events: all;
    z-index: 1;

    .license-card {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      margin: auto;
      max-width: 75%;
      width: 800px;
      max-height: 75%;
    }

    .license-content {
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      align-items: stretch;

      textarea {
        font-family: monospace;
      }
    }
  }
}

@media (max-width: 1279px) {
  .ui-overlay {
    .info-card {
      width: 100%;
    }
  }
}

#chart {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 25%;
  min-height: 300px;
  z-index: 1;
  color: white;
  filter: drop-shadow(0px 0px 1px black) drop-shadow(0px 0px 6px black);

  .title,
  .legend-left,
  .legend-right {
    text-anchor: middle;
    font-size: 12px;
    fill: white;
  }

  .title {
    text-decoration: underline;
    font-size: 16px;
  }

  .legend-left {
    fill: steelblue;
    transform: rotate(90deg);
    transform-origin: 0% 50%;
  }

  .legend-right {
    fill: red;
    transform: rotate(-90deg);
    transform-origin: 100% 50%;
  }
}

.current-time {
  pointer-events: none;
}

.current-time-drag {
  opacity: 0%;
  pointer-events: all;
  cursor: pointer;
}
